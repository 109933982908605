import getters from './getters';
import mutations from './mutations';
import actions from './actions';

export default {
  namespaced: true,

  state: {
    post: null,
    postCategories: [],
    postSourceType: [],
  },

  getters,
  actions,
  mutations,
};
