<template>
  <VApp v-if="show">
    <TheNavigation />

    <TheAppBar />

    <VContent>
      <VContainer grid-list-xl>
        <RouterView />
      </VContainer>
    </VContent>

    <Snackbar />
  </VApp>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { USER_MODULE } from 'Store/modules-types';
import { LOAD } from 'Store/actions-types';

import TheAppBar from 'Components/TheAppBar';
import TheNavigation from 'Components/TheNavigation';

export default {
  name: 'App',

  metaInfo: {
    title: 'Неизвестная страница',
    titleTemplate: '%s | ZabNews Monitor',
  },

  components: {
    TheAppBar,
    TheNavigation,
  },

  data: () => ({
    show: false,
  }),

  computed: {
    ...mapState(USER_MODULE, {
      token: state => state.token,
    }),
  },

  async mounted() {
    if (!this.token) {
      window.location.replace('/login');

      return;
    }
    this.$http.setToken(this.token);

    await this.load();

    this.show = true;
  },

  methods: {
    ...mapActions({
      load: LOAD,
    }),
  },
};
</script>

<style lang="scss">
@import '~Styles/app';
</style>
