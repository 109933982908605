import { LOAD } from 'Store/actions-types';
import { USER_MODULE, POST_MODULE } from 'Store/modules-types';
import { LOAD_USER } from 'Store/user/actions-types';
import {
  LOAD_POST_CATEGORIES,
  LOAD_POST_SOURCE_TYPE,
} from 'Store/post/actions-types';

export default {
  [LOAD]: ({ dispatch }) =>
    new Promise(resolve => {
      Promise.all([
        dispatch(`${USER_MODULE}/${LOAD_USER}`),
        dispatch(`${POST_MODULE}/${LOAD_POST_CATEGORIES}`),
        dispatch(`${POST_MODULE}/${LOAD_POST_SOURCE_TYPE}`),
      ]).then(() => {
        resolve();
      });
    }),
};
