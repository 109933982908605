import Vue from 'vue';
import { LOAD_USER } from './actions-types';
import {
  SET_USER_ID,
  SET_USER_NAME,
  SET_USER_LOGIN,
  SET_USER_EMAIL,
} from './mutations-types';

export default {
  async [LOAD_USER]({ commit }) {
    return new Promise(resolve => {
      Vue.prototype.$http.get('/user', null).then(response => {
        if (response.meta.code !== Vue.prototype.$http.RESPONSE_STATUS_OK) {
          return;
        }

        const user = response.result;

        commit(SET_USER_ID, user.id);
        commit(SET_USER_NAME, user.name);
        commit(SET_USER_LOGIN, user.login);
        commit(SET_USER_EMAIL, user.email);

        resolve();
      });
    });
  },
};
