import axios from 'axios';
import qs from 'qs';

/** Класс для работы с запросами к серверу. */
export default class Http {
  constructor() {
    this.http = axios;
    this.http.defaults.paramsSerializer = params => qs.stringify(params);
    this.http.defaults.withCredentials = true;
    this.http.defaults.baseURL = `${process.env.API_URL}/monitor`;

    this.initClassConst();
  }

  /**
   * Инифиализация констант класса.
   */
  initClassConst() {
    this.RESPONSE_STATUS_OK = 1;
    /** Статус "Ошибка валидации" */
    this.RESPONSE_STATUS_VALIDATE_ERROR = 10;
    this.RESPONSE_STATUS_UNAUTHORIZED = 20;
    this.RESPONSE_STATUS_FORBIDDEN = 30;
    this.RESPONSE_STATUS_BAD_REQUEST = 40;
  }

  /**
   * Выполняет GET запрос к серверу
   * @param {string} url - Адрес на который посылается запрос
   * @param {object} params - Параметры GET запроса
   */
  get(url, params) {
    return new Promise(resolve => {
      this.http
        .get(url, { params })
        .then(response => resolve(response.data))
        .catch(error => {
          if (url === '/user') {
            console.log(error);
            localStorage.clear();
            window.location.replace('/login');
          }
        });
    });
  }

  /**
   * Выполняет POST запрос к серверу
   * @param {string} url - Адрес на который посылается запрос.
   * @param {object} params - Параметры POST запроса.
   */
  post(url, params) {
    return new Promise(resolve => {
      this.http.post(url, params).then(response => resolve(response.data));
    });
  }

  /**
   * Выполняет DELETE запрос к серверу
   * @param {string} url - Адрес на который посылается запрос
   */
  delete(url) {
    return new Promise(resolve => {
      this.http.delete(url).then(response => resolve(response.data));
    });
  }

  /**
   * Выполняет отправку файла на сервер
   * @param {string} url - Адрес на который посылается запрос.
   * @param {File} file - Файл для отправки.
   */
  uploadFile(url, file) {
    return new Promise(resolve => {
      const formData = new FormData();

      formData.append('file', file);

      this.http
        .post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => resolve(response.data));
    });
  }

  setToken(token) {
    this.token = token;

    // noinspection JSUnresolvedVariable
    this.http.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  /**
   * Выполняет отправку файлов на сервер
   * @param {string} url - Адрес на который посылается запрос.
   * @param {object} file - Файл для отправки.
   * @param {number} fileIndex Индекс файла для отображения статуса загрузки.
   * @param {function} successCallback - Функция обработки успешного выполнения запроса.
   * @param {function} errorCallback - Функция обработки ошибки при выполнении запроса.
   * @param onUploadProgressCallback - Функция обработки стадии загрузки файлов на сервер.
   */
  // eslint-disable-next-line class-methods-use-this
  uploadFileRequest(
    url,
    file,
    fileIndex,
    onUploadProgressCallback,
    successCallback,
    errorCallback
  ) {
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    const requestConfig = {
      onUploadProgress(progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        onUploadProgressCallback(percentCompleted, fileIndex);
        return percentCompleted;
      },
    };
    axios
      .post(url, file, requestConfig)
      .then(response => successCallback(response))
      .catch(error => errorCallback(error));
  }
}
