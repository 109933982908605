import {
  SET_POST_CATEGORIES,
  UPDATE_POST_CATEGORY,
  ADD_POST_CATEGORY,
  SET_POST_SOURCE_TYPE,
} from './mutation-types';

export default {
  [SET_POST_CATEGORIES]: (state, postCategories) => {
    state.postCategories = postCategories;
  },

  [UPDATE_POST_CATEGORY]: (state, updatedPostCategory) => {
    if (!updatedPostCategory || !updatedPostCategory.id) {
      return;
    }

    const postCategoryIndex = state.postCategories.findIndex(
      postCategory => postCategory.id === updatedPostCategory.id
    );

    if (postCategoryIndex === -1) {
      return;
    }

    state.postCategories.splice(postCategoryIndex, 1, updatedPostCategory);
  },

  [ADD_POST_CATEGORY]: (state, newPostCategory) => {
    if (!newPostCategory || !newPostCategory.id) {
      return;
    }

    const postCategoryIndex = state.postCategories.findIndex(
      postCategory => postCategory.id === newPostCategory.id
    );

    if (postCategoryIndex !== -1) {
      return;
    }

    state.postCategories.push(newPostCategory);
  },

  [SET_POST_SOURCE_TYPE]: (state, postSourceType) => {
    state.postSourceType = postSourceType;
  },
};
