import ru from 'vee-validate/dist/locale/ru';

export const validateDictionary = {
  ru: {
    messages: { ...ru.messages },
    names: {
      login: 'Логин',
      password: 'Пароль',
      name: 'Название',
      title: ' ',
      categoryId: ' ',
      keywords: ' ',
      publishedAtTime: ' ',
    },
  },
};
