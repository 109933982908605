import Vue from 'vue';

Vue.prototype.$renderErrors = (errors, validationObserver) => {
  if (!errors) {
    return;
  }

  validationObserver.reset();
  validationObserver.setErrors(errors);
};
