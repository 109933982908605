<template>
  <VSnackbar v-model="show" :color="color" top right>
    {{ title }}
    <VBtn text @click="show = false">
      Закрыть
    </VBtn>
  </VSnackbar>
</template>

<script>
import { events } from './events';

export default {
  name: 'TheSnackbar',

  data: () => ({
    show: false,
    color: 'success',
    title: '',
  }),

  mounted() {
    events.$on('show', this.showSnackbar);
  },

  methods: {
    showSnackbar(type, title) {
      this.color = type;
      this.title = title;
      this.show = true;
    },
  },
};
</script>

<style scoped></style>
