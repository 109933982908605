import {
  SET_USER_ID,
  SET_USER_NAME,
  SET_USER_LOGIN,
  SET_USER_EMAIL,
} from './mutations-types';

export default {
  [SET_USER_ID](state, id) {
    state.id = id;
  },

  [SET_USER_NAME](state, name) {
    state.name = name;
  },

  [SET_USER_LOGIN](state, login) {
    state.login = login;
  },

  [SET_USER_EMAIL](state, email) {
    state.email = email;
  },
};
