import Vue from 'vue';
import Vuex from 'vuex';

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

import user from './user';
import post from './post';

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',

  state: {
    navigationCollapse: null,
    isLoadingData: false,
  },

  getters,
  actions,
  mutations,

  modules: {
    user,
    post,
  },
});

if (module.hot) {
  module.hot.accept([], () => {
    const newGetters = import('./getters');
    const newActions = import('./actions');
    const newMutations = import('./mutations');

    store.hotUpdate({
      getters: newGetters,
      actions: newActions,
      mutations: newMutations,

      modules: {
        user,
        post,
      },
    });
  });
}

export default store;
