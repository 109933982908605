import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import ru from 'vuetify/lib/locale/ru';
import colors from 'vuetify/lib/util/colors';

import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: {
      ru,
    },
    current: 'ru',
  },
  iconfont: 'mdi',
  theme: {
    themes: {
      light: {
        primary: colors.indigo.base,
        secondary: colors.teal.base,
        accent: colors.amber.base,
        error: colors.red.base,
        warning: colors.yellow.base,
        info: colors.grey.base,
        success: colors.green.base,
      },
    },
  },
});
