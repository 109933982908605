import Vue from 'vue';
import { LOAD_POST_CATEGORIES, LOAD_POST_SOURCE_TYPE } from './actions-types';
import { SET_POST_CATEGORIES, SET_POST_SOURCE_TYPE } from './mutation-types';

export default {
  async [LOAD_POST_CATEGORIES]({ commit }) {
    return new Promise(resolve => {
      Vue.prototype.$http.get('/post_category/find', null).then(response => {
        if (response.meta.code !== Vue.prototype.$http.RESPONSE_STATUS_OK) {
          return;
        }

        const { postCategories } = response.result;

        commit(SET_POST_CATEGORIES, postCategories);

        resolve();
      });
    });
  },

  async [LOAD_POST_SOURCE_TYPE]({ commit }) {
    return new Promise(resolve => {
      Vue.prototype.$http.get('/post_source_type/find', null).then(response => {
        if (response.meta.code !== Vue.prototype.$http.RESPONSE_STATUS_OK) {
          return;
        }

        const { postSourceType } = response.result;

        commit(SET_POST_SOURCE_TYPE, postSourceType);

        resolve();
      });
    });
  },
};
