import TheSnackbar from './TheSnackbar';
import { events } from './events';

const Snackbar = {
  install(Vue, params = {}) {
    this.params = params;

    Vue.component('Snackbar', TheSnackbar);

    Vue.prototype.$snackbar = (type, title) => {
      events.$emit('show', type, title);
    };
  },
};

export default Snackbar;
