import getters from './getters';
import mutations from './mutations';
import actions from './actions';

export default {
  namespaced: true,

  state: {
    login: '',
    name: '',
    token: localStorage.getItem('accessToken') || null,
  },

  getters,
  actions,
  mutations,
};
