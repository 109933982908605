import * as Rules from 'vee-validate/dist/rules';
import { extend, localize, setInteractionMode } from 'vee-validate';
import { validateDictionary } from 'Utils/validateDictionary';

Object.keys(Rules).forEach(rule => {
  extend(rule, Rules[rule]);
});

localize(validateDictionary);
localize('ru');

setInteractionMode('eager');
