<template>
  <VAppBar app dark color="primary">
    <VAppBarNavIcon @click="changeNavigationCollapse()" />

    <VProgressLinear
      :active="isLoadingData"
      indeterminate
      absolute
      bottom
      color="accent"
    />

    <VSpacer />

    <VToolbarItems>
      <VMenu bottom offset-y>
        <template v-slot:activator="{ on }">
          <VBtn text icon v-on="on">
            <VAvatar size="38px">
              <img
                src="https://zabnews.ru/images/favicon-120x120.png"
                alt="Логотип"
              />
            </VAvatar>
          </VBtn>
        </template>

        <VList dense>
          <VListItem @click="logout()">
            <VListItemAction>
              <VIcon>
                mdi-exit-to-app
              </VIcon>
            </VListItemAction>

            <VListItemContent>
              Выйти
            </VListItemContent>
          </VListItem>
        </VList>
      </VMenu>
    </VToolbarItems>
  </VAppBar>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { CHANGE_NAVIGATION_COLLAPSE } from 'Store/mutations-types';

export default {
  name: 'TheAppBar',

  computed: {
    ...mapState({
      isLoadingData: state => state.isLoadingData,
    }),
  },

  methods: {
    ...mapMutations({
      changeNavigationCollapse: CHANGE_NAVIGATION_COLLAPSE,
    }),

    async logout() {
      const response = await this.$http.delete('/logout');

      if (response.meta.code !== this.$http.RESPONSE_STATUS_OK) {
        return;
      }

      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');

      window.location = `${window.location.origin}/login`;
    },
  },
};
</script>

<style scoped lang="scss"></style>
