<template>
  <v-navigation-drawer v-model="navigationCollapse" app fixed>
    <v-toolbar text color="transparent">
      <v-list>
        <v-list-item>
          <v-list-item-title class="title">
            ZabNews
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-toolbar>

    <v-list class="pt-0" dense>
      <v-divider />

      <v-list-item :to="{ name: 'dashboard' }" exact>
        <v-list-item-action>
          <v-icon>mdi-view-dashboard</v-icon>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>Главная</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-group prepend-icon="mdi-chat" no-action>
        <template v-slot:activator>
          <v-list-item-title>Новости</v-list-item-title>
        </template>

        <v-list-item :to="{ name: 'createNews' }">
          <v-list-item-content>
            <v-list-item-title>Добавить новость</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-action>
        </v-list-item>

        <v-list-item :to="{ name: 'newsList' }" exact>
          <v-list-item-content>
            <v-list-item-title>Список новостей</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-list-item-action>
        </v-list-item>

        <v-list-item :to="{ name: 'newsListDraft' }" exact>
          <v-list-item-content>
            <v-list-item-title>Список черновиков</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list-group>

      <v-list-group prepend-icon="mdi-format-page-break" no-action>
        <template v-slot:activator>
          <v-list-item-title>Статьи</v-list-item-title>
        </template>

        <v-list-item :to="{ name: 'createArticle' }">
          <v-list-item-content>
            <v-list-item-title>Добавить статью</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-action>
        </v-list-item>

        <v-list-item :to="{ name: 'articlesList' }" exact>
          <v-list-item-content>
            <v-list-item-title>Список статей</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list-group>

      <v-list-group prepend-icon="mdi-image-area" no-action>
        <template v-slot:activator>
          <v-list-item-title>Фоторепортажи</v-list-item-title>
        </template>

        <v-list-item :to="{ name: 'createPhotoEssay' }">
          <v-list-item-content>
            <v-list-item-title>Добавить фоторепортаж</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-action>
        </v-list-item>

        <v-list-item :to="{ name: 'photoEssaysList' }" exact>
          <v-list-item-content>
            <v-list-item-title>Список фоторепортажей</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list-group>

      <v-list-group prepend-icon="mdi-volume-source" no-action>
        <template v-slot:activator>
          <v-list-item-title>Подкасты</v-list-item-title>
        </template>

        <v-list-item :to="{ name: 'createPodcast' }">
          <v-list-item-content>
            <v-list-item-title>Добавить подкаст</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-action>
        </v-list-item>

        <v-list-item :to="{ name: 'podcastList' }" exact>
          <v-list-item-content>
            <v-list-item-title>Список подкастов</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list-group>

      <v-list-group prepend-icon="mdi-video-outline" no-action>
        <template v-slot:activator>
          <v-list-item-title>Видео</v-list-item-title>
        </template>

        <v-list-item :to="{ name: 'createVideo' }">
          <v-list-item-content>
            <v-list-item-title>Добавить видео</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-action>
        </v-list-item>

        <v-list-item :to="{ name: 'videosList' }" exact>
          <v-list-item-content>
            <v-list-item-title>Список видео</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list-group>

      <v-list-group prepend-icon="mdi-blogger" no-action>
        <template v-slot:activator>
          <v-list-item-title>Блоги</v-list-item-title>
        </template>

        <v-list-item :to="{ name: 'createBlog' }">
          <v-list-item-content>
            <v-list-item-title>Добавить блог</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-action>
        </v-list-item>

        <v-list-item :to="{ name: 'blogsList' }" exact>
          <v-list-item-content>
            <v-list-item-title>Список блогов</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list-group>

      <v-list-group prepend-icon="mdi-card-text" no-action>
        <template v-slot:activator>
          <v-list-item-title>Комментарии</v-list-item-title>
        </template>

        <v-list-item :to="{ name: 'commentsList' }">
          <v-list-item-content>
            <v-list-item-title>Модерация комментариев</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'commentsPublished' }">
          <v-list-item-content>
            <v-list-item-title>Опубликованные</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-group prepend-icon="mdi-television-guide" no-action>
        <template v-slot:activator>
          <v-list-item-title>Реклама</v-list-item-title>
        </template>

        <v-list-item :to="{ name: 'createPartner' }">
          <v-list-item-content>
            <v-list-item-title>Добавить рекламу</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'partnerList' }">
          <v-list-item-content>
            <v-list-item-title>Список рекламы</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'partnersStatistic' }">
          <v-list-item-content>
            <v-list-item-title>Статистика рекламы</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-group prepend-icon="mdi-settings" no-action>
        <template v-slot:activator>
          <v-list-item-title>Настройки</v-list-item-title>
        </template>

        <v-list-item :to="{ name: 'postCategoriesSettings' }">
          <v-list-item-content>
            <v-list-item-title>Категории постов</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-group prepend-icon="mdi-delete" no-action>
        <template v-slot:activator>
          <v-list-item-title>Архив</v-list-item-title>
        </template>

        <v-list-item :to="{ name: 'archive' }">
          <v-list-item-content>
            <v-list-item-title>Список архивных</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-group prepend-icon="mdi-smoking" no-action>
        <template v-slot:activator>
          <v-list-item-title>Статистика</v-list-item-title>
        </template>

        <v-list-item :to="{ name: 'commonStat' }">
          <v-list-item-content>
            <v-list-item-title>Общая</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'jourStat' }">
          <v-list-item-content>
            <v-list-item-title>По журналистам</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'statistics' }">
          <v-list-item-content>
            <v-list-item-title>Журналисты</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-group prepend-icon="mdi-rss" no-action>
        <template v-slot:activator>
          <v-list-item-title>СМИ</v-list-item-title>
        </template>

        <v-list-item :to="{ name: 'RSSList' }" exact>
          <v-list-item-content>
            <v-list-item-title>Лента</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>mdi-view-headline</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item :to="{ name: 'SMIList' }" exact>
          <v-list-item-content>
            <v-list-item-title>Сайты</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>mdi-web</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item :to="{ name: 'SMICreate' }" exact>
          <v-list-item-content>
            <v-list-item-title>Добавить сайт</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item :to="{ name: 'SMIStatisticsList' }" exact disabled>
          <v-list-item-content>
            <v-list-item-title>Статистика</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>mdi-chart-bar</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list-group>

      <v-list-item :to="{ name: 'logs' }" exact>
        <v-list-item-action>
          <v-icon>mdi-math-log</v-icon>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>Журнал</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapMutations } from 'vuex';
import { CHANGE_NAVIGATION_COLLAPSE } from 'Store/mutations-types';

export default {
  name: 'TheNavigation',

  data: () => ({
    isLoading: false,
  }),

  computed: {
    navigationCollapse: {
      get() {
        return this.$store.state.navigationCollapse;
      },
      set(state) {
        if (state !== this.$store.state.navigationCollapse) {
          this.changeNavigationCollapse();
        }
      },
    },
  },

  methods: {
    ...mapMutations({
      changeNavigationCollapse: CHANGE_NAVIGATION_COLLAPSE,
    }),
  },
};
</script>

<style scoped lang="scss"></style>
