import {
  format,
  parseISO,
  formatRelative,
  formatDistanceToNow,
  differenceInCalendarDays,
} from 'date-fns';

import { ru } from 'date-fns/locale';

export function relativeDate(value) {
  if (!value) {
    return null;
  }

  const date = parseISO(value);
  const nowDate = Date.now();

  if (Math.abs(differenceInCalendarDays(nowDate, date)) > 1) {
    return formatRelative(parseISO(value), nowDate, {
      locale: ru,
      weekStartsOn: 1,
    });
  }

  return formatDistanceToNow(date, {
    locale: ru,
    addSuffix: true,
    includeSeconds: true,
  });
}

export function dateTime(value) {
  if (!value) {
    return null;
  }

  return format(parseISO(value), 'd MMMM y HH:mm', {
    locale: ru,
  });
}

export function onlyTime(value) {
  if (!value) {
    return null;
  }

  return format(parseISO(value), 'HH:mm');
}
