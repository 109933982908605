import Vue from 'vue';

import 'Configuration/http';

import 'Configuration/snackbar';
import 'Configuration/vue-meta';
import 'Configuration/vee-validate';
import vuetify from 'Configuration/vuetify';

import 'Utils/prototypes';
import * as filters from 'Utils/filters';

import App from './App.vue';

import store from './store';
import { router } from './router';

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
