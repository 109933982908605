import {
  CHANGE_NAVIGATION_COLLAPSE,
  SET_LOADING_DATA_STATUS,
} from './mutations-types';

export default {
  [CHANGE_NAVIGATION_COLLAPSE](state) {
    state.navigationCollapse = !state.navigationCollapse;
  },

  [SET_LOADING_DATA_STATUS]: (state, loadingDataStatus) => {
    state.isLoadingData = loadingDataStatus;
  },
};
