import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const scrollBehavior = function scrollBehavior(to, from, savedPosition) {
  let position = { x: 0, y: 0 };

  if (savedPosition) {
    position = savedPosition;
  }

  return new Promise(resolve => {
    this.app.$root.$once('triggerScroll', () => {
      resolve(position);
    });
  });
};

export const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior,

  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import('Views/Dashboard'),
    },
    {
      path: '/news',
      name: 'newsList',
      component: () => import('Views/news/NewsList'),
    },
    {
      path: '/news/:id(\\d+)/edit',
      name: 'editNews',
      component: () => import('Views/news/EditNews'),
    },
    {
      path: '/news-draft',
      name: 'newsListDraft',
      component: () => import('Views/news/NewsListDraft'),
    },
    {
      path: '/news/create',
      name: 'createNews',
      component: () => import('Views/news/CreateNews'),
    },
    {
      path: '/articles',
      name: 'articlesList',
      component: () => import('Views/article/ArticlesList'),
    },
    {
      path: '/article/create',
      name: 'createArticle',
      component: () => import('Views/article/CreateArticle'),
    },
    {
      path: '/article/:id(\\d+)/edit',
      name: 'editArticle',
      component: () => import('Views/article/EditArticle'),
    },
    {
      path: '/photo_essays',
      name: 'photoEssaysList',
      component: () => import('Views/photoEssay/PhotoEssaysList'),
    },
    {
      path: '/photo_essay/create',
      name: 'createPhotoEssay',
      component: () => import('Views/photoEssay/CreatePhotoEssay'),
    },
    {
      path: '/photo_essay/:id(\\d+)/edit',
      name: 'editPhotoEssay',
      component: () => import('Views/photoEssay/EditPhotoEssay'),
    },
    {
      path: '/blogs',
      name: 'blogsList',
      component: () => import('Views/blog/BlogsList'),
    },
    {
      path: '/blog/create',
      name: 'createBlog',
      component: () => import('Views/blog/CreateBlog'),
    },
    {
      path: '/blog/:id(\\d+)/edit',
      name: 'editBlog',
      component: () => import('Views/blog/EditBlog'),
    },
    {
      path: '/podcast',
      name: 'podcastList',
      component: () => import('Views/podcast/PodcastList'),
    },
    {
      path: '/podcast/create',
      name: 'createPodcast',
      component: () => import('Views/podcast/CreatePodcast'),
    },
    {
      path: '/prodcast/:id(\\d+)/edit',
      name: 'editPodcast',
      component: () => import('Views/podcast/EditPodcast'),
    },
    {
      path: '/videos',
      name: 'videosList',
      component: () => import('Views/video/VideosList'),
    },
    {
      path: '/video/create',
      name: 'createVideo',
      component: () => import('Views/video/CreateVideo'),
    },
    {
      path: '/video/:id(\\d+)/edit',
      name: 'editVideo',
      component: () => import('Views/video/EditVideo'),
    },
    {
      path: '/comments',
      name: 'commentsList',
      component: () => import('Views/comments/CommentsList'),
    },
    {
      path: '/comments/published',
      name: 'commentsPublished',
      component: () => import('Views/comments/CommentsPublished'),
    },
    {
      path: '/partners',
      name: 'partnerList',
      component: () => import('Views/partner/PartnerList'),
    },
    {
      path: '/partner/create',
      name: 'createPartner',
      component: () => import('Views/partner/CreatePartner'),
    },
    {
      path: '/partner/:id(\\d+)/edit',
      name: 'editPartner',
      component: () => import('Views/partner/EditPartner'),
    },
    {
      path: '/partnersStatistic',
      name: 'partnersStatistic',
      component: () => import('Views/partner/PartnersStatistic'),
    },
    {
      path: '/partner/:id(\\d+)/statistic',
      name: 'statisticPartner',
      component: () => import('Views/partner/StatisticPartner'),
    },
    {
      path: '/partner/:id(\\d+)/statistic/:date',
      name: 'statisticDatePartner',
      component: () => import('Views/partner/StatisticPartner'),
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('Views/Settings/SettingsView'),
      children: [
        {
          path: '/post_categories',
          name: 'postCategoriesSettings',
          component: () => import('Views/Settings/PostCategoriesSettings'),
        },
      ],
    },
    {
      path: '/statistics',
      name: 'statistics',
      component: () => import('Views/statistics/statisticsList'),
    },
    {
      path: '/statistics/:id(\\d+)',
      name: 'statisticsItem',
      component: () => import('Views/statistics/statisticsItem'),
    },
    {
      path: '/statistics/:id(\\d+)/:date',
      name: 'statisticsItemDate',
      component: () => import('Views/statistics/statisticsItem'),
    },
    {
      path: '/smi',
      name: 'SMIList',
      component: () => import('Views/smi/SMIList'),
    },
    {
      path: '/smi/:id(\\d+)',
      name: 'SMIEdit',
      component: () => import('Views/smi/SMIEdit'),
    },
    {
      path: '/smi/create',
      name: 'SMICreate',
      component: () => import('Views/smi/SMICreate'),
    },
    {
      path: '/smi/statistics',
      name: 'SMIStatisticsList',
      component: () => import('Views/smi/SMIStatisticsList'),
    },
    {
      path: '/smi/rss',
      name: 'RSSList',
      component: () => import('Views/smi/RSSList'),
    },
    {
      path: '/logs',
      name: 'logs',
      component: () => import('Views/logs/logsList'),
    },
    {
      path: '/logs/:id(\\d+)',
      name: 'logsId',
      component: () => import('Views/logs/logsList'),
    },
    {
      path: '/logs/:table/:id(\\d+)',
      name: 'logsTable',
      component: () => import('Views/logs/logsList'),
    },
    {
      path: '/archive',
      name: 'archive',
      component: () => import('Views/Archive'),
    },
    {
      path: '/commonStat',
      name: 'commonStat',
      component: () => import('Views/statistics/CommonStat'),
    },
    {
      path: '/jourStat',
      name: 'jourStat',
      component: () => import('Views/statistics/JourStat'),
    },
    {
      path: '/jourStat/:id(\\d+)/:date_begin/:date_end',
      name: 'jourStatItem',
      component: () => import('Views/statistics/JourStatItem'),
    },
  ],
});
